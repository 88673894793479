import React, { useContext, useEffect } from "react";
import { Layout, Row, Col, Card, Typography } from "antd"
import EditUserForm from "../components/user_EditForm"
import { GlobalStateContext, GlobalDispatchContext } from "../context/GlobalContextProvider"
import LoadingPage from "../components/loadingPage"
const { Header, Content } = Layout;
const { Title } = Typography;

const Account = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    if(state.auth_state)
      dispatch({ type:'ROUTE', route: "/account"})
  }, [state.auth_state, dispatch])

  const refreshAccount = () => {
    dispatch({ type:'REFRESH', toRefresh: 'refreshAccount'});
  }

  return(
    <div id="account-page" className="page">
      <Header id="header">
        <Row className="main-row">
          <Col className="title" span={6}>
            <Title>Account</Title>
          </Col>
          <Col flex="auto">
            <Row gutter={12} justify="end">
              {/* <Col className="create-booking" span={6}>
                <Button block type="primary">Update my info</Button>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <Row className="lower-row" justify="start" gutter={12}>
        </Row>
      </Header>
      <Content>
        <LoadingPage className={state.accountLoaded ? "loaded" : "loading" } />
        <Row className="content-container" gutter={[20, 20]}>
          <Col span={24}>
            <Card className="account-card" loading={!state.accountLoaded}>
              <Row className="lower-row" justify="start" gutter={12}>
                <Col span={24}>
                  <EditUserForm user={state.accountData} type="account" hideRole={true} onSuccess={refreshAccount}/>
                </Col>
              </Row>
            </Card> 
          </Col>
        </Row>
      </Content>
    </div>
  )
}

export default Account;

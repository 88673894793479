import React, { useEffect, useContext, useState } from "react"
import { message, Button, Row, Col, Form, Input, Select, Alert } from "antd"
import { GlobalStateContext} from "../context/GlobalContextProvider"
import { APIEditUser } from "../scripts/users";

const { Option } = Select;

const EditUserForm = (props) => {
  const state = useContext(GlobalStateContext)
  const [showOperator, setShowOperator] = useState(false)

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const [statusMessage, setStatusMessage] = useState("")
  const [statusVerbose, setStatusVerbose] = useState("")

  const [form] = Form.useForm();

  // ===========================
  // API REQUESTS
  // ===========================
  const editUser = async data => {
    console.log(data)
    setLoading(true)
    setStatus("")
    setStatusMessage("")
    setStatusVerbose("")

    await APIEditUser(data, props.type)
      .then(response => {
        console.log(response)
        setLoading(false)
        message.success("User updated!")
        props.onSuccess();
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        if(error.type === "validation"){
          setStatus("error")
          setStatusMessage("User role not selected")
          setStatusVerbose("Please check the user details again")
        }
        else{
          setStatus("error")
          setStatusMessage("An error occured!")
          setStatusVerbose(error.error)
        }
      })
  }

  // ===========================
  // RENDER FUNCTIONS
  // ===========================

  // Show the operator/vendor select 
  const onRoleChange = (value) => setShowOperator(value === "operator")

  // Render the options for vendors if loaded.
  const renderVendorOptions = () => {
    return state.vendorsData.map((vendor, index) => {
      return <Option key={index} value={vendor}>{ vendor}</Option>
    })
  }

  // Handle for the user props changing
  const handleUserChange = () => {
    if(props.user){
      if(form){
        onReset();
      }
      props.user.role === "operator" ? setShowOperator(true) : setShowOperator(false)
    }
  }
  useEffect(handleUserChange, [props.user]);

  // Reset the form data to the initial props.
  const onReset = () => {
    let form_data = {...props.user}
    if(form_data.phone_number)
      form_data.phone_number = form_data.phone_number.replace("+61","")
    form.setFieldsValue(form_data)
  }

  console.log(props.user)
  return (
    <Form layout="horizontal" form={form} hideRequiredMark onFinish={event => editUser(event)}>
      <Form.Item
        preserve
        hidden
        name="username"
        label="username">
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        labelAlign="left"
        rules={[
          {
            type: 'email',
            message: 'Please enter an email',
          },
          {
            required: true,
            message: 'Please enter an email',
          },
        ]}
      >
        <Input
          style={{ width: '100%' }}
          placeholder="Please enter an email"
        />
      </Form.Item>
      <Form.Item
          name="phone_number"
          label="Phone Number"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          labelAlign="left"
          rules={[{ required: true, message: 'Please enter a phone number' }]}
        >
          <Input addonBefore="+61" style={{ width: '100%' }} pattern="[0-9]+" placeholder="Please enter a phone number"/>
      </Form.Item>
      <Form.Item
        name="role"
        label="Role"
        hidden={props.hideRole}
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        labelAlign="left"
        hasFeedback
        rules={[{ required: true, message: 'Please select a role' }]}
      >
        <Select onChange={onRoleChange} placeholder="Please select a role">
          <Option value="operator">Operator</Option>
          {state.role_level > 0 && <Option value="admin">Admin</Option>}
          {state.role_level > 1 && <Option value="superadmin">Superadmin</Option>}
        </Select>
      </Form.Item>
      { showOperator &&
      <Form.Item
        name="profile"
        label="Operator"
        hidden={props.hideRole}
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        labelAlign="left"
        hasFeedback
        rules={[{ required: true, message: 'Please select a vendor' }]}
      >
        <Select loading={!state.vendorsLoaded} placeholder="Please select a vendor">
          {state.vendorsLoaded && renderVendorOptions()}
        </Select>
      </Form.Item>
      }
      <Form.Item
        name="given_name"
        label="Given name"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        labelAlign="left"
        rules={[{ required: false, message: 'Please enter a given name' }]}
      >
        <Input placeholder="Please enter a given name" />
      </Form.Item>
      <Form.Item
        name="family_name"
        label="Family name"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        labelAlign="left"
        rules={[{ required: false, message: 'Please enter a family name' }]}
      >
        <Input placeholder="Please enter a family name" />
      </Form.Item>
      <Form.Item>
        <Row gutter={12}>
          <Col offset={8} span={8}>
            <Button loading={loading} block type="primary" htmlType="submit">
              Update {props.type === "account" ? "Account" : "User" }
            </Button>             
          </Col>
          <Col span={8}>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form.Item>
      { status !== "" && <Alert
        message={statusMessage}
        description={statusVerbose}
        type={status}
      />}
    </Form>
   )
}

EditUserForm.defaultProps = {
  type: "user",
  user: {},
  onSuccess: () => {},
  hideRole: false
}

export default EditUserForm

